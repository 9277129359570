//
// Header
//

.header {
    position: relative;
}

.header-gradient {
    background: linear-gradient(87deg, #002f34 0, #1c7370 100%) !important;
}
